import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";
import { CodePreview } from "../components/docs/CodePreview";
import TwoColumnSection from "../components/TwoColumnSection";

const Page = ({ data }) => (
	<Layout
		pageMeta={{
			title: "Contact profiles",
		}}>
		<div>
			<section className="aboveFold">
				<div className="container">
					<h1>All your subscribers in one place</h1>

					<p className="maxWidth-750 text-large text-dark itemCenter mb-30">
						All your subscribers in one neatly organized place that always has
						enough room — we don’t charge per subscriber. Collect email
						addresses, and grow your email list with simple to set up subscribe
						forms that are easy to share.
					</p>

					<a
						className="button button--bright button--huge mb-70"
						href="https://client.sidemail.io/register"
						style={{ minWidth: 300 }}>
						Start 7-day trial
					</a>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/contacts.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Contact properties"
					text="Make the data structure fit your business, not the other way around. Contact properties are a simple, flexible way to define what you can collect, store, and explore in contact profiles."
					list={[
						"Unlimited key-value pairs",
						"String, number and date types",
						"Simple to add and no forced structure",
					]}
					img={data.propertiesImage.childImageSharp.fluid}
				/>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Search and filters"
					text="Find the easier way to explore and dive into your data inside of contact profiles. Use chainable filter conditions and real-time search to find what you're looking for in an instant."
					list={[
						"Lightning-fast search",
						"Easy-to-use filtering with multiple conditions",
					]}
					img={data.filtersImage.childImageSharp.fluid}
				/>
			</section>

			<section
				id="contact-profiles-api-section"
				className="centeredCodeSection container">
				<h2>Keep contact profiles up-to-date with API</h2>

				<p>
					Let your application automatically add and update data about your
					users inside Sidemail with a single create-update HTTP API request.
				</p>

				<Link to="/docs/contact-profiles-quickstart/">
					Step-by-step integration guide
				</Link>

				<CodePreview language="text">
					{`
POST https://api.sidemail.io/v1/contacts
Authorization: Bearer xxxxxxxxxxxx
Content-Type: application/json
{
  "emailAddress": "john.doe@example.com",
  "identifier": "123",
  "customProps": {
    "full_name": "John doe",
    "pricing_plan": "premium",
    "registered_at": "2019-08-15T13:20:39.160Z",
    "last_seen_at": "2019-08-20T17:40:39.160Z"
   }
}
					`}
				</CodePreview>
			</section>

			<section className="container">
				<TwoColumnSection
					title="Subscribe forms"
					text={
						<p className="text text--isLast">
							Collect new contacts effortlessly without touching a code.
							Sidemail subscribe forms are super easy to set up, customizable,
							and wrapped in lovely design which fits your brand.
						</p>
					}
					cta={<Link to="/subscribe-forms">Subscribe forms</Link>}
					img={data.subscribeFormsImage.childImageSharp.fluid}
				/>
			</section>

			<section className="moreFeaturesSection container">
				<ul>
					<li>
						<h4>CSV import</h4>
						<p>
							Import your mailing list by uploading a CSV file and mapping out
							the correct data fields.
						</p>
					</li>

					<li>
						<h4>Nice and friendly UI</h4>
						<p>
							We designed Sidemail not only to look nice but also to be
							easy-to-use. Everything is as clean and user-friendly as possible.
						</p>
					</li>

					<li>
						<h4>First-hand support</h4>
						<p>
							If needed, you'll get personal help from real people, directly
							from the development team.
						</p>
					</li>
				</ul>
			</section>

			<CtaSection />
		</div>
	</Layout>
);

export default Page;

export const query = graphql`
	query {
		propertiesImage: file(
			relativePath: { eq: "sidemail-public--automation-contacts.png" }
		) {
			...twoColumnsImage
		}
		filtersImage: file(
			relativePath: { eq: "sidemail-public--contacts-search.png" }
		) {
			...twoColumnsImage
		}
		subscribeFormsImage: file(
			relativePath: { eq: "sidemail-public--forms-design.png" }
		) {
			...twoColumnsImage
		}
	}
`;
